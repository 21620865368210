<template>
  <div class="modal fade" id="license-form-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="campaign-modalLabel">License</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <select class="form-control"
                  v-model="form.type">
            <option value="full">Full License</option>
            <option value="influencer">Collab License</option>
            <option value="attribution-x">Attribution-X License</option>
          </select>

          <input type="text"
                 class="form-control mt-2"
                 placeholder="Note"
                 v-model="form.note">

          <div class="form-check mt-2">
            <input type="checkbox"
                   v-model="form.manage_account"
                   class="form-check-input">
            <label class="form-check-label">Manage Account</label>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-rounded my-2"
                  @click.prevent="submit">Save changes
          </button>
          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      form: {
        type: 'full',
        note: '',
        manage_account: '0'
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('license-form-modal'))
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.axios.post('/licenses', this.form)
        .then(() => {
          this.hide();
          this.$emit('added');
        });
    }
  }
}
</script>