<template>
  <div class="modal fade" id="edit-note-modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="campaign-modalLabel">Note</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <input type="text"
                 class="form-control mt-2"
                 placeholder="Note"
                 v-model="form.note">
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary btn-rounded my-2"
                  @click.prevent="submit">Save changes
          </button>
          <button type="button" class="btn btn-dark btn-rounded my-2" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      license: null,
      form: {
        note: '',
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('edit-note-modal'))
  },
  methods: {
    show(license) {
      this.license = license;
      this.form.note = license.note;

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.axios.patch('/licenses/' + this.license.id, this.form)
        .then(() => {
          this.hide();
          this.$emit('updated');
        });
    }
  }
}
</script>